import "./resource-bench-filter-pane.styl"
import template from "./resource-bench-filter-pane.pug"

### Auth, Real-Time & Stores ###
import { authManager } from "@/lib/managers/auth-manager"
import { groupStore } from "@/stores/group-store"

### Popups ###
import { PopupPane } from "@/lib/components/popup/popup-pane"

### Models ###
import { PermissionLevel } from "@/models/permission-level"

## UI Assets ##
import { MultiDropDownItem } from "@/lib/components/drop-downs/multi-drop-down"

import ko from "knockout"

export class ResourceBenchFilterPane extends PopupPane
   constructor: (selectedPositionIds, selectedTagIds) ->
      assertArgs(arguments, Function, Function)
      super(template())

      @canViewPeopleTags = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PEOPLE_TAGS)
      @tagCategoriesEnabled = authManager.companyModules()?.tagCategories

      # Popup Properties
      @title = ko.observable("Resource Filters")
      @enableOverflow = ko.observable(true)

      @selectedPositionIds = selectedPositionIds
      @editingPositions = ko.observableArray()
      @positionOptions = ko.observableArray()

      @selectedTagIds = selectedTagIds
      @editingTags = ko.observableArray()
      @tagOptions = ko.observableArray()
      @categorizedTags = ko.observable()

      @rightActionBtn = ko.observable({
         callback: @applyChanges
         text: "Apply"
         })

      @loadData()

   applyChanges: =>
      @selectedPositionIds(@editingPositions().map (item) -> return item.value())
      @selectedTagIds(@editingTags().map (item) -> return item.value())
      @dismissHandler()

   loadData: ->
      entities = ['positions']

      if @canViewPeopleTags
         if @tagCategoriesEnabled
            entities.push('categorized-tags')
         else
            entities.push('tags')

      # TODO: change to use core CompanyStore.getCompanyEntityOptions with group filter
      groupStore.getGroupEntities authManager.selectedGroupId(), entities, (err, data) =>
         return console.log "error: ", err if err
         @positionOptions data.positionOptions.map (position) =>
            selected = @selectedPositionIds().indexOf(position.value()) != -1
            item = new MultiDropDownItem(position.name(), position.value(), selected)
            @editingPositions.push(item) if selected
            return item

         if @canViewPeopleTags
            if data.tagOptions? and data.tagOptions.length > 0
               @tagOptions data.tagOptions.map (tag) =>
                  selected = @selectedTagIds().indexOf(tag.value()) != -1
                  item = new MultiDropDownItem(tag.name(), tag.value(), selected)
                  @editingTags.push(item) if selected
                  return item
            else if data.categorizedTagOptions?
               for key, val of data.categorizedTagOptions
                  for item in val
                     selected = @selectedTagIds().indexOf(item.value()) != -1
                     if selected
                        item.baggage({group: key})
                        item.selected(selected)
                        @editingTags.push(item) 
               @categorizedTags(data.categorizedTagOptions)
